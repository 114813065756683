<template>
  <b-tab
    lazy
    title="SEO Client Reports"
    :disabled="data.disableTab"
    :title-item-class="{ 'd-none': data.disableTab }"
  >
    <b-row>
      <b-col cols="12" style="height: calc(100vh - 380px); overflow: hidden;">
        <iframe :src="data.reportingUrl" style="width: inherit; height: inherit; border:0" allowfullscreen />
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        reportingUrl: '',
        disableTab: true,
      }),
    },
  },
}
</script>
