<template>
  <b-tab
    lazy
    title="Client Reports"
    :disabled="data.clientReport.disableTab"
    :title-item-class="{ 'd-none': data.clientReport.disableTab }"
  >
    <b-row>
      <b-col cols="12" style="height: calc(100vh - 380px); overflow: hidden;">
        <iframe :src="data.clientReport.reportingUrl" style="width: inherit; height: inherit; border:0" allowfullscreen />
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
      },
    },
  },
}
</script>
